.custom__empty__data {
  $root: &;
  padding: 4rem 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  @media (max-width: 600px) {
    height: calc(100% - 6.4rem);
  }
  &__title {
    font-size: 3.2rem;
    letter-spacing: -0.012em;
    margin-top: 4rem;
    color: #536380;
    font-weight: 900;
    line-height: 128%;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 3.6rem;
    }
  }
  &__message {
    margin-top: 1rem;
    font-size: 2.4rem;
    color: #536380;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  }
  &--sidebar {
    padding: 4rem;
    #{$root}__title {
      margin-top: 2rem;
      font-size: 2.4rem;
    }
    #{$root}__message {
      font-size: 1.4rem;
      text-align: center;
    }
  }
}
