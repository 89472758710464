.headerOrigin {
  position: relative;
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    &__item {
      display: flex;
      align-items: center;
      gap: 2rem;
      @media (max-width: 1024px) {
        text-align: center;
        justify-content: center;
        width: 100%;
      }
      p {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
        color: #272b2f;
      }
      &__sub {
        display: flex;
        gap: 1rem;
        align-items: center;
        a {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #525560;
          &:hover {
            color: red;
            transition: all 0.2s linear;
          }
        }
      }
    }
    &__item:last-child {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  &__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.5rem;
    height: 46px;
    &__logo {
      display: flex;
      align-items: center;
      width: 14.3rem;
      height: 5.1rem;
      cursor: pointer;
      img {
        width: 100% !important;
        max-height: 9rem !important;
        padding: 5px 0 !important;
      }
    }
    &__menuBar {
      display: none;
      @media (max-width: 1024px) {
        display: block;
        position: relative;
        z-index: 9999;
      }
    }
    &__cart {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
  &__marsk {
    display: none;
    @media (max-width: 1024px) {
      display: block;
      position: absolute;
      top: 0%;
      left: 0%;
      bottom: 0%;
      right: 0%;
      background-color: rgba(0, 0, 0, 0.45);
      width: 100%;
      height: 100vh;
      z-index: 999;
    }
  }
  &__sub {
    width: 100%;
    margin: 0 auto;
    background-color: #686868;
    height: 4.6rem;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      position: absolute;
      background-color: unset;
      z-index: 999;
      top: 0%;
      right: 0;
      width: unset;
      height: unset;
      opacity: 1;
      transform: translateX(0%);
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1024px) {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        width: 29rem;
        background-color: #ffffff;
        min-height: 100vh;
        justify-content: unset;
        gap: 4rem;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      }
      a {
        font-size: 1.6rem !important;
        font-weight: 400 !important;
        color: #ffffffcc;
        border-right: 1px solid #ffffffcc;
        padding-right: 1.5rem;
        line-height: 1.5rem;
        @media (max-width: 1024px) {
          border-bottom: 1px solid #52556030;
          border-right: none;
          padding-bottom: 2rem;
          padding-right: unset;
          width: 100%;
          color: #525560;
          padding-left: 2rem;

          &:first-child {
            padding-top: 5rem;
          }
        }
      }
      a:last-child {
        border-right: none;
      }
    }
    &__active {
      width: 100%;
      margin: 0 auto;
      background-color: #686868;
      height: 4.6rem;
      display: flex;
      align-items: center;
      @media (max-width: 1024px) {
        position: absolute;
        background-color: unset;
        z-index: 999;
        top: 0%;
        right: 0;
        width: unset;
        height: unset;
        opacity: 0;
        transform: translateX(100%);
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        display: block;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;
        max-width: 1050px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 1024px) {
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          width: 40rem;
          background-color: #ffffff;
          min-height: 100vh;
        }
        a {
          font-size: 1.5rem;
          font-weight: 300;
          color: #ffffffcc;
          border-right: 2px solid #ffffffcc;
          padding-right: 1.5rem;
          line-height: 1.6rem;
          @media (max-width: 1024px) {
            border-bottom: 1px solid #ffffffcc;
            border-right: none;
            padding-bottom: 2rem;
            padding-right: unset;
          }
          &:hover {
            color: red;
            transition: all 0.2s linear;
          }
        }
        a:last-child {
          border-right: none;
        }
      }
    }
  }
}
.headerOrigin-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 0 4rem !important;
  }
  @media (max-width: 856px) {
    padding: 0 2rem !important;
  }
}
