.footerOrigin {
  margin-top: auto;
  position: relative;
  width: 100%;
  &__main {
    background-color: #353535;
    height: 64px;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    &__container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1024px) {
        padding: 0 4rem;
      }
      @media (max-width: 856px) {
        padding: 0 2rem !important;
      }
      &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        ul {
          display: flex;
          align-items: center;
          a {
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            color: #ffffffcc;
            padding: 0 1rem;
            &:hover {
              color: red;
              transition: all 0.2s linear;
            }
            @media (max-width: 475px) {
              padding: 0 2rem 0 0;
              font-size: 15px;
            }
            @media (max-width: 390px) {
              font-size: 12px;
            }
          }
        }
        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          p {
            font-size: 12px;
            font-weight: 700;
            line-height: 24px;
            color: #ffffffcc;
            padding: 0 2rem;
          }
        }
      }
    }
  }
  &__sub {
    background-color: #2b2b2b;
    height: 44px;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    @media (max-width: 475px) {
      height: auto;
    }
    @media (max-width: 390px) {
      height: 75px;
    }
    &__container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1024px) {
        padding: 0 4rem;
      }
      @media (max-width: 856px) {
        padding: 0 2rem !important;
      }

      &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 475px) {
          flex-direction: column-reverse;
        }
        ul {
          display: flex;
          align-items: center;
          @media (max-width: 475px) {
            flex-direction: column;
          }
          li {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #ffffffcc;
            padding: 0 1rem;
            &:last-child {
              font-weight: 400;
              color: #959595;
            }
            @media (max-width: 675px) {
              font-size: 12px;
            }
            @media (max-width: 475px) {
              padding: 0 2rem 0 0;
              margin-bottom: 0.5rem;
            }
          }
          a {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #959595;
            padding: 0 1rem;
            &:hover {
              color: #ffffffcc;
              transition: all 0.2s linear;
            }
            &:last-child {
              font-weight: 400;
              color: #959595;
              &:hover {
                color: #ffffffcc;
                transition: all 0.2s linear;
              }
              @media (max-width: 475px) {
                padding-left: 1rem;
              }
            }
            @media (max-width: 675px) {
              font-size: 12px;
            }
            @media (max-width: 475px) {
              padding: 0 2rem 0 0;
            }
          }
        }
        &__item {
          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            font-size: 12px;
            font-weight: 400;
            color: #959595;
            line-height: 20px;
            padding: 0 2rem;
            &:hover {
              color: #ffffffcc;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }
}
