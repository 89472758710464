.homeOrigin {
  margin-bottom: 60px;
  &__content {
    width: 100%;
    margin: 5rem auto 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2,
    p {
      margin: 0 auto;
    }
    h2 {
      font-size: 2.7rem;
      font-weight: 500;
      line-height: 3.9rem;
      color: #555;
      letter-spacing: -0.384px;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: -0.336px;
      @media (max-width: 768px) {
        font-size: 2.4rem;
        line-height: 3rem;
      }
      @media (max-width: 475px) {
        font-size: 2rem;
      }
    }
    p {
      color: #555;
      font-size: 24px;
      line-height: 128%; /* 35.84px */
      letter-spacing: -0.336px;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 3rem;
      }
      @media (max-width: 475px) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
  &__search {
    margin: 5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      margin: 5rem 0;
    }
    &__item {
      width: 75rem !important;
      border-radius: 2.4rem !important;
      height: 6.3rem !important;
      display: flex;
      justify-content: center;
      text-align: center;
      @media (max-width: 768px) {
        width: 65rem !important;
      }
      @media (max-width: 675px) {
        width: 50rem !important;
      }
      @media (max-width: 475px) {
        width: 40rem !important;
      }
      @media (max-width: 390px) {
        width: 35rem !important;
      }
      input {
        text-align: center !important;
        font-size: 2.4rem !important;
        line-height: 3rem;
        font-weight: 400;
        color: #272b2f !important;
        border-radius: inherit !important;
      }
      &:hover {
        background-color: #fff !important;
        box-shadow: 0 1px 6px #20212447;
        border-color: #dfe1e500;
      }
    }
  }

  &__card {
    // display: none !important;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    &__item {
      text-align: center;
    }
    span {
      border-radius: 12px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    }
    p {
      color: #272b2f;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 152%; /* 24.32px */
    }

    &__main {
      width: 500px;
      height: 315px;
      margin: 10px 10px auto;
      border-radius: 20px;
      background-image: url("../../assets/images/card-bef.png") !important;
      background-size: contain;
      padding: 164px 0 0 170px;
      background-repeat: no-repeat;
      box-shadow: #0000003d 0 3px 8px;
      position: relative;
      text-align: left;
      @media (max-width: 475px) {
        padding: 28.5vw 1% 0% 33%;
        width: 86vw;
        height: 54vw;
        margin: 10px 0 0;
      }
      &__table {
        width: 100% !important;
        border-collapse: separate;
        border-spacing: 14px 6px;
        font-size: 15px;
        color: #000;
        &__item {
          vertical-align: middle;
          font-weight: 500;
          line-height: unset;
          padding: 0;
          font-size: 15px;
          line-height: 21px !important;
          @media (max-width: 475px) {
            font-size: 14px;
            line-height: 15px !important;
          }
          @media (max-width: 390px) {
            font-size: 12px;
            line-height: 12px !important;
          }
        }
      }
      &__qr {
        width: 8rem;
        padding: 3px;
        background-color: #fff;
        border-radius: 8px;
        position: absolute;
        bottom: 4px;
        right: 31px;
        z-index: 99;
        @media (max-width: 475px) {
          width: 5rem;
          padding: 0px;
        }
      }
    }

    &__info {
      margin: 30px;
      border: rgb(204, 0, 0) 1px solid;
      border-radius: 20px;
      p {
        background: rgb(204, 0, 0);
        padding: 8px 10px;
        color: #fff;
        border-radius: 20px 20px 0 0;
        min-width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
      }
      &__table {
        border-color: #ececec;
        border-collapse: separate;
        margin-bottom: 1em;
        width: 100%;
        padding: 0 6px !important;
        margin: 0;
        border-spacing: 0;

        &__left {
          color: #000000b3;
          font-size: 16px;
          line-height: 14px;
          white-space: nowrap;
          text-align: right;
          padding-left: 10px;
          @media (max-width: 475px) {
            font-size: 15px;
          }
        }
        td {
          border-bottom: 1px solid #ececec;
          padding: 1rem;

          &:first-child {
            padding-left: 0;
          }
        }

        &__right {
          vertical-align: middle;
          font-weight: 600;
          padding-right: 10px !important;
          text-transform: uppercase;
          line-height: 2.2;
          color: #000;
          padding-left: 20px !important;
          font-size: 18px;
          text-align: left;
          @media (max-width: 475px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  &__description {
    // display: block;
    // display: none;
    margin-top: 30px;
    margin-bottom: 80px;
    &__list {
      background-image: url("../../assets/images/bg.png");
      z-index: 1;
      max-width: 1000px;
      margin: 0px auto auto;
      padding-bottom: 30px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: -120px;
      background-position-y: -2px;
      overflow: hidden;
      @media (max-width: 1024px) {
        padding: 0 4rem !important;
      }
      @media (max-width: 768px) {
        padding: 0 0rem !important;
      }
      &__item {
        padding: 3rem;
        width: 60%;
        @media (max-width: 768px) {
          width: 100%;
        }
        p {
          font-size: 16px;
          color: #222222;
          font-weight: 400;
          line-height: 24px;
          font-style: normal;
          margin-bottom: 2rem;
          font-style: normal;
          a {
            margin: 1rem 0;
            font-size: 16px;
            color: #222222;
            font-weight: 400;
            font-style: normal;
            line-height: 24px;
            &:hover {
              color: red;
              transition: all 0.2s linear;
            }
          }
          strong {
            margin: 1rem 0;
            font-size: 16px;
            color: #222222;
            font-weight: 700;
            font-style: normal;
            line-height: 24px;
          }
        }
        span {
          margin: 1rem 0;
          padding-left: 3rem;
          display: block;
          font-size: 16px;
          color: #222222;
          font-weight: 400;
          font-style: normal;
          line-height: 24px;
          a {
            margin: 1rem 0;
            font-size: 16px;
            color: #222222;
            font-weight: 400;
            line-height: 24px;
            &:hover {
              color: red;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }
}
.ant-input-affix-wrapper:focus {
  background-color: #fff !important;
}
